<template>
    <div class="home">
          <div class="logo"></div>
          <div class="bottom">
              <div class="contactInformation">
                  <div class="title">联系方式</div>
                  <p>商务邮箱：yzy@xiaoyangtaoquan.com</p>
                  <p>联系电话：18601934240</p>
                  <p>来访地址：黑龙江省大庆高新区新风路8号服务外包产业园D区D-1广房951-63室</p>
              </div>
              <div class="QRcode"></div>
              <div class="copyright"><div class="policeLogo"><img src="../assets/police.png" alt=""></div><a style="margin-right: 10px;" href="https://beian.mps.gov.cn/#/query/webSearch?code=23060002000182" rel="noreferrer" target="_blank">黑公网安备23060002000182</a><a href="https://beian.miit.gov.cn/" target="_blank">黑ICP备2024022979号-1</a></div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
      data(){
          return{
            brand:""
          }
      },
      created(){
        this.brand = this.judgeBrand(navigator.userAgent.toLowerCase()); 
        alert(this.brand)
      },
      methods:{
          downloadAPK(){
              alert("暂不支持下载")
          },
          judgeBrand(sUserAgent) {
            var isIphone = sUserAgent.match(/iphone/i) == "iphone";
            var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
            var isHonor = sUserAgent.match(/honor/i) == "honor";
            var isOppo = sUserAgent.match(/oppo/i) == "oppo";
            var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
            var isVivo = sUserAgent.match(/vivo/i) == "vivo";
            var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
            var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
            var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
            var isSamsung = sUserAgent.match(/sm-/i) == "sm-";

            if (isIphone) {
                return 'iphone';
            } else if (isHuawei || isHonor) {
                return 'huawei';
            } else if (isOppo || isOppoR15) {
                return 'oppo';
            } else if (isVivo) {
                return 'vivo';
            } else if (isXiaomi || isRedmi || isXiaomi2s) {
                return 'xiaomi';
            } else if (isSamsung) {
                return 'samsung';
            } else {
                return 'default';
            }
     }
      }
  }
  </script>
  
  <style scoped>
      .home{
          width:100%;
          height:100%;
          background-color:#fff;
          position: relative;
         
      }
      
      .logo{
          width:400px;
          height:400px;
          background-image:url(../assets/logo.png);
          background-size:contain;
          background-position:center;
          position: absolute;
          top: 65px;
          left: calc(50% - 200px);
          -moz-user-select: none; 
          -khtml-user-select: none; 
          user-select: none;
      }
      .link{
          width:322px;
          height:66px;
          background-image:url(../assets/rectangle.png);
          background-size:contain;
          background-position:center;
          position: absolute;
          top: 465px;
          left: calc(50% - 161px);
          text-align: center;
          line-height: 66px;
          -moz-user-select: none; 
          -khtml-user-select: none; 
          user-select: none;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 22px;
          color: #323232;
          font-style: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          -moz-user-select: none; 
            -khtml-user-select: none; 
              user-select: none;
      }
      #androidLogo{
          flex: 0 0 auto;
          display: inline-block;
          height: 32px;
          width: 32px;
          margin-right: 8px;
          flex-basis: 32px;
          background-image:url(../assets/icon_android.png);
          background-size:contain;
          background-position:center;
      }
      @media (-webkit-min-device-pixel-ratio:2) {
          .logo{
              width:400px;
              height:400px;
              background-image:url(../assets/logo@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 65px;
              left: calc(50% - 200px);
          }
          .link{
              width:322px;
              height:66px;
              background-image:url(../assets/rectangle@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 465px;
              left: calc(50% - 161px);
              text-align: center;
              line-height: 66px;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 22px;
              color: #323232;
              font-style: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
          }
          #androidLogo{
              flex: 0 0 auto;
              display: inline-block;
              height: 32px;
              width: 32px;
              margin-right: 8px;
              flex-basis: 32px;
              background-image:url(../assets/icon_android@2x.png);
              background-size:contain;
              background-position:center;
          }
      }
      .bottom{
          width: 100%;
          height: 26vh;
          background: #FFC655;
          position: fixed;
          bottom: 0px;
      }
      .contactInformation{
          position: absolute;
          top: 3.7vh;
          left: 5.2vw;
          text-align: left;
      }
      .contactInformation .title{
          display: inline-block;
          height: 33px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 2.3vh;
          color: #FFFFFF;
          line-height: 33px;
          font-style: normal;
          -moz-user-select: none; 
          -khtml-user-select: none; 
          user-select: none;
      }
      .contactInformation p {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.5vh;
          color: #FFFFFF;
          line-height: 22px;
          text-align: left;
          font-style: normal;
      }
      .QRcode{
          width: 13vh;
          height: 13vh;
          border: 1px solid #979797;
          position: absolute;
          top: 3.7vh;
          right: 5.2vw;
          background-image:url(../assets/qrcodeNew.png);
            background-size:contain;
            background-position:center;
      }
      .copyright{
          width: 100%;
          height: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.3vh;
          color: #FFFFFF;
          line-height: 20px;
          text-align:center;
          font-style: normal;
          position: absolute;
          bottom: 2vh;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .policeLogo{
        display: inline-block;
        display: flex;
        margin-right: 5px;
      }
      .policeLogo img{
        width: 1.3vh;
      }
      .copyright a{
        color: #fff;
        text-decoration: none;
      }
      @media screen and (max-width: 768px){
          .bottom{
              width: 100%;
              height: 420px;
              background: #FFC655;
              position: fixed;
              bottom: 0px;
              border-radius: 5%,5%,0,0;
          }
          .contactInformation{
              position: static;
              margin-top: 10px;
              text-align: left;
          }
          .contactInformation .title{
              display: inline-block;
              width: 100%;
              height: 33px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 24px;
              color: #FFFFFF;
              line-height: 33px;
              font-style: normal;
              text-align: center;
          }
          .contactInformation p {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              text-align: center;
              display: block;
              color: #FFFFFF;
              line-height: 22px;
              font-style: normal;
          }
          .QRcode{
              width: 140px;
              height: 140px;
              /* background: #D8D8D8; */
              border: 1px solid #979797;
              position: absolute;
              top: auto;
              bottom: 60px;
              right: calc(50% - 70px);
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
              background-image:url(../assets/qrcodeNew.png);
              background-size:contain;
              background-position:center;
          }
          .copyright{
              width: 100%;
              height: 20px;
              font-family: PingFangSC, PingFang SC;
              font-size: 10px;
              color: #FFFFFF;
              line-height: 20px;
              text-align:center;
              font-style: normal;
              bottom: 10px;
          }
          .policeLogo img{
                width: 12px;
            }
          .copyright a{
            color: #fff;
            text-decoration: none;
        }
      }
      @media screen and (max-width: 440px){
          .logo{
              width:200px;
              height:200px;
              background-image:url(../assets/logo.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 140px;
              left: calc(50% - 100px);
          }
          .link{
              width:329px;
              height:50px;
              background-image:url(../assets/rectangle2@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 403px;
              left: calc(50% - 164.5px);
              text-align: center;
              line-height: 66px;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 22px;
              color: #323232;
              font-style: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
          }
          #androidLogo{
              flex: 0 0 auto;
              display: inline-block;
              height: 32px;
              width: 32px;
              margin-right: 8px;
              flex-basis: 32px;
              background-image:url(../assets/icon_android@2x.png);
              background-size:contain;
          }
          .bottom{
            width: 100%;
            height: 143px;
            background: #FFC655;
            position: fixed;
            bottom: 0px;
        }
        .contactInformation{
            position: static;
            text-align: left;
            padding: 16px;
            padding-top: 10px;
            margin-top: 0px;
        }
        .contactInformation .title{
            display: inline-block;
            width: 100%;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            line-height: 33px;
            font-style: normal;
            text-align: left;
        }
        .contactInformation p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            display: block;
            color: #FFFFFF;
            line-height: 17px;
            margin: 2px 0;
            font-style: normal;
          }
        .QRcode{
            display: none;
        }
      }
      @media screen and (max-width: 375px){
          .logo{
              width:200px;
              height:200px;
              background-image:url(../assets/logo@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 140px;
              left: calc(50% - 100px);
          }
          .link{
            width:329px;
              height:50px;
              background-image:url(../assets/rectangle2@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 403px;
              left: calc(50% - 164.5px);
              text-align: center;
              line-height: 66px;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 22px;
              color: #323232;
              font-style: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
          }
          #androidLogo{
              flex: 0 0 auto;
              display: inline-block;
              height: 32px;
              width: 32px;
              margin-right: 8px;
              flex-basis: 32px;
              background-image:url(../assets/icon_android@2x.png);
              background-size:contain;
          }
      }
      @media screen and (max-width: 320px){
        .link{
             width:257px;
              height:39px;
              background-image:url(../assets/rectangle2@2x.png);
              background-size:contain;
              background-position:center;
              position: absolute;
              top: 403px;
              left: calc(50% - 128.5px);
              text-align: center;
              line-height: 66px;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 22px;
              color: #323232;
              font-style: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              -moz-user-select: none; 
              -khtml-user-select: none; 
              user-select: none;
          }
      }
      /* 自适应多种手机屏幕 */
  
  </style>