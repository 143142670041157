<template>
  <div class="homePage">
        <div class="topRow">
            <div class="logo"></div>小羊淘券
        </div>
        <div class="phoneLogo"></div>
        <div class="link" @click="downloadAPK">
            <span id="androidLogo"></span>
            <span>android 下载</span>
        </div>
        <div class="introduceBox">
            <div class="introduceWordBox">
                <div class="introduceTitle">越逛越省钱</div>
                <div class="introduceWord">小羊淘券与淘宝、天猫、京东、拼多多、唯品会等大型平台建立合作，通过平台间无缝连接，共同打造集“衣食住行游购娱”于一体的超级生活入口，实现购物省钱、分享赚钱，满足网购爱好者对品质好货与极致性价比的追求，并同时享受大平台购物权益保障。</div>
                <div class="pclink" @click="downloadAPK">
                    <span id="pcandroidLogo"></span>
                    <span>android 下载</span>
                </div>
            </div>
            <div class="introducePicBox">
            </div>
        </div>
        <div class="bridge">核心优势</div>
        <div class="tileBox">
            <div class="tileBox1">
                <div class="titleBoxTitle">一站式服务</div>
                <div class="titleBoxWord">围绕生活化场景提供产品和服务提供一站式服务，以折扣单品为亮点，日常优惠券、下单返利、折扣代金券等为核心内容</div>
                <div class="tileBox1Logo"></div>
            </div>
            <div class="tileBox2">
                <div class="titleBoxTitle">核心优势</div>
                <div class="titleBoxWord">我们拥有专业的选品团队，保障给用户推荐的商品最优化；APP操作便捷，提供简单、高效的操作流程</div>
                <div class="tileBox2Logo"></div>
            </div>
            <div class="tileBox3">
                <div class="titleBoxTitle">专业技术团队</div>
                <div class="titleBoxWord">技术团队经验丰富、技术精湛，具备深厚的互联网技术和数据分析能力，能够确保APP的稳定运行和持续优化</div>
                <div class="tileBox3Logo"></div>
            </div>
        </div>
        <div class="presistence"></div>
        <div class="bottom">
              <div class="contactInformation">
                  <div class="title">联系方式</div>
                  <p>商务邮箱：yzy@xiaoyangtaoquan.com</p>
                  <p>联系电话：18601934240</p>
                  <p>来访地址：黑龙江省大庆高新区新风路8号服务外包产业园D区D-1广房951-63室</p>
              </div>
              <div class="QRcode"></div>
              <div class="copyright"><div class="policeLogo"><img src="../assets/police.png" alt=""></div><a style="margin-right: 10px;" href="https://beian.mps.gov.cn/#/query/webSearch?code=23060002000182" rel="noreferrer" target="_blank">黑公网安备23060002000182</a><a href="https://beian.miit.gov.cn/" target="_blank">黑ICP备2024022979号-1</a></div>
          </div>
  </div>
</template>

<script>
export default {
    methods:{
          downloadAPK(){
             // alert("暂不支持下载")
            // window.open("https://a.app.qq.com/o/simple.jsp?pkgname=com.jb.taoquan&fromcase=70051&g_f=1182517&scenevia=XQYFX")
            window.open("https://m.malink.cn/s/Z7JR73  ")
          }
      }
}
</script>

<style scoped>
    .homePage{
        width: 100%;
        font-family: PingFangSC-Medium;
    }
    .topRow{
        height: 120px;
        width: 100%;
        line-height: 120px;
        font-family:  PingFangSC-MediumC;
        font-weight: 500;
        font-size: 30px;
        color: rgba(50, 50, 50, 1);
        font-style: normal;
        position: relative;
        padding-left: 172px;
        box-sizing: border-box;
        text-align: left;
    }
    .logo{
        width: 60px;
        height: 60px;
        position: absolute;
        left: 100px;
        top: 30px;
        background-image:url(../assets/logo2.png);
        background-size:contain;
        background-position:center;
    }
    
    .introduceBox{
        height: 700px;
        width: 100%;
    }
    .introduceWordBox,.introducePicBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
    }
    .introduceWordBox{
        float: left;
        padding-left: 6.94vw;
        padding-top: 13.8vw;
        padding-right: 4.1vw;
        box-sizing: border-box;
        text-align: left;
    }
    .introducePicBox{
        background-image:url(../assets/homepage_jpg@2x.png);
        background-size:cover;
        background-position:center;
    }
    .introduceWord{
        /* position: absolute;
        top: 355px;
        left: 13%;  */
        text-align: left;
        font-family:  PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: rgba(50, 60, 80, 1);
        font-style: normal;
        word-break: break-all;
        margin-top: 2.1vw;
        text-align: justify;
    }
    .pclink{
        width:329px;
        height:50px;
        /* position: absolute;
        top: 479px;
        left: 13%;  */
        margin-top: 4.1vw;
        background-image:url(../assets/rectangle2@2x.png);
        background-repeat: no-repeat;
        background-size:contain;
        background-position:center;
        text-align: center;
        line-height: 66px;
        -moz-user-select: none; 
        -khtml-user-select: none; 
        user-select: none;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #323232;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -moz-user-select: none; 
            -khtml-user-select: none; 
            user-select: none;
    }
    #pcandroidLogo{
        flex: 0 0 auto;
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-right: 8px;
        flex-basis: 24px;
        background-image:url(../assets/icon_android@2x.png);
        background-size:contain;
        background-position:center;
    }
    .introduceTitle{
        /* position: absolute; */
        /* top: 232px;
        left: 13%; */
        /* height: 13.4%; */
        font-weight: bold;
        font-family:  PingFangSC-Medium;
        font-size: 6.46vw;
        background: linear-gradient(to bottom, rgba(254, 196, 76, 1), rgba(252, 145, 37, 1)); 
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: left;
    }
    .bridge{
        height: 221px;
        line-height: 221px;
        font-weight: bold;
        font-size: 32px;
        color: rgba(50, 60, 80, 1);
        font-family:  PingFangSC-Medium;
        background-color:#FAFAFA;
    }
    .tileBox{
        height: 280px;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 6.9%;
        display: flex;
        justify-items:center;
        align-items: center;
        justify-content: space-between;
        background-color:#FAFAFA;
    }
    .tileBox1{
        display: inline-block;
        width: 27.2vw;
        height: 280px;
        border-radius: 8px;
        background: linear-gradient( to bottom, #FEC44C 0%, #FC9125 100%);
        padding: 2.5vw;
        box-sizing: border-box;
        position: relative;
    }
    .tileBox2{
        display: inline-block;
        width: 27.2vw;
        height: 280px;
        border-radius: 8px;
        background: linear-gradient( to bottom,  #FFC756 0%, #FFB626 100%);
        padding: 2.5vw;
        position: relative;
        box-sizing: border-box;
    }
    .tileBox3{
        display: inline-block;
        width:27.2vw;
        height: 280px;
        border-radius: 8px;
        background: linear-gradient( to bottom,  #81B9FF 0%, #067EFF 100%);
        padding: 2.5vw;
        position: relative;
        box-sizing: border-box;
    }
    .presistence{
        background-color:#FAFAFA;
        height: 199px;
        width: 100%;
    }
    .tileBox1Logo{
        width: 8.3vw;
        height: 8.3vw;
        background-image: url(../assets/icon1@2x.png);
        background-size:contain;
        background-position:center;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .tileBox2Logo{
        width: 8.3vw;
        height: 8.3vw;
        background-image: url(../assets/icon2@2x.png);
        background-size:contain;
        background-position:center;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .tileBox3Logo{
        width: 8.3vw;
        height: 8.3vw;
        background-image: url(../assets/icon3@2x.png);
        background-size:contain;
        background-position:center;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .titleBoxTitle{
        height: 40px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        margin-bottom: 24px;
    }
    .titleBoxWord{
        height: 143px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: left;
        font-style: normal;
    }
    .bottom{
          width: 100%;
          height: 29vh;
          background: #FFC655;
          position: relative;
      }
      .contactInformation{
          position: absolute;
          top: 3.7vh;
          left: 5.2vw;
          text-align: left;
      }
      .contactInformation .title{
          display: inline-block;
          height: 3vh;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 2.3vh;
          color: #FFFFFF;
          line-height: 3vh;
          font-style: normal;
          -moz-user-select: none; 
          -khtml-user-select: none; 
          user-select: none;
      }
      .contactInformation p {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.5vh;
          color: #FFFFFF;
          line-height: 2vh;
          text-align: left;
          font-style: normal;
      }
      .QRcode{
          width: 13vh;
          height: 13vh;
          border: 1px solid #979797;
          position: absolute;
          top: 3.7vh;
          right: 5.2vw;
          background-image:url(../assets/qrcodeNew.png);
            background-size:contain;
            background-position:center;
      }
      .copyright{
          width: 100%;
          height: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 1.3vh;
          color: #FFFFFF;
          line-height: 20px;
          text-align:center;
          font-style: normal;
          position: absolute;
          bottom: 2vh;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .policeLogo{
        display: inline-block;
        display: flex;
        margin-right: 5px;
      }
      .policeLogo img{
        width: 1.3vh;
      }
      .copyright a{
        color: #fff;
        /* text-decoration: none; */
      }
      .phoneLogo{
        display: none;
      }
      .link{
        display: none;
      }
      @media screen and (max-width: 768px){
        .pclink{
            display: none;
        }
        .topRow{
            display: none;
        }
        .phoneLogo{
            width:38.9vw;
            height:38.9vw;
            background-image:url(../assets/logo@2x.png);
            background-size:contain;
            background-position:center;
            margin:  26.6vw 30.6vw;
            display: block;
            -moz-user-select: none; 
            -khtml-user-select: none; 
            user-select: none;
        }
        .link{
            width:87.73vw;
            height:13.3vw;
            background-image:url(../assets/rectangle2@2x.png);
            background-repeat: no-repeat;
            background-size:contain;
            background-position:center;
            margin: 0 auto 39.47vw;
            text-align: center;
            line-height: 66px;
            -moz-user-select: none; 
            -khtml-user-select: none; 
            user-select: none;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 5.3vw;
            color: #323232;
            font-style: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            -moz-user-select: none; 
                -khtml-user-select: none; 
                user-select: none;
        }
        #androidLogo{
            flex: 0 0 auto;
            display: inline-block;
            height: 6.4vw;
            width: 6.4vw;
            margin-right: 8px; 
            background-image:url(../assets/icon_android@2x.png);
            background-size:contain;
            background-position:center;
        }
        .introduceBox{
           /* display: flex; */
           height: auto;
        }
        .introduceWordBox,.introducePicBox{
            width: 100%;
            display: block;
            position: relative;
        }
        .introduceWordBox{
            height: 70.9vw;
            padding: 0px 4.26vw;
            box-sizing: border-box;
            background-color: #fff;
            float: none;
        }
        .introducePicBox{
            background-image:url(../assets/iphonepage_jpg@2x.png);
            background-size:cover;
            background-position:center;
        }
        .introduceWord{
            text-align: left;
            font-family:  PingFangSC-Medium;
            font-weight: 500;
            font-size: 3.73vw;
            color: rgba(50, 60, 80, 1);
            font-style: normal;
            position: static;
            line-height: 6.9vw;
            width: auto;
        }
        .introduceTitle{
            font-weight: bold;
            text-align: left;
            font-family:  PingFangSC-Medium;
            font-size: 6vw;
            background: linear-gradient(to bottom, rgba(254, 196, 76, 1), rgba(252, 145, 37, 1)); 
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: static;
        }
        .introducePicBox{
            width: 100vw;
            height: 97.3vw;
        }
        .bridge{
            height: 28.8vw;
            line-height: 28.8vw;
            font-weight: bold;
            font-size: 5.3vw;
            color: rgba(50, 60, 80, 1);
            font-family: PingFangSC-Medium;
            background-color: #FAFAFA;
        }
        .tileBox{ height: auto;
            width: 100%;
            box-sizing: border-box;
            padding: 0px 4vw 6.6vw 4vw;
            display: block;
            background-color:#FAFAFA;
        }
        .tileBox1{
            display: block;
            height: 57.6vw;
            border-radius: 2.13vw;
            background: linear-gradient( to bottom, #FEC44C 0%, #FC9125 100%);
            padding: 8.53vw 4.26vw;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            margin-bottom: 4vw;
        }
        .tileBox2{
            display: block;
            height:57.6vw;
            border-radius:2.13vw;
            background: linear-gradient( to bottom,  #FFC756 0%, #FFB626 100%);
            padding: 8.53vw 4.26vw;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 4vw;
        }
        .tileBox3{
            display:block;
            height: 57.6vw;
            border-radius: 2.13vw;
            background: linear-gradient( to bottom,  #81B9FF 0%, #067EFF 100%);
            padding: 8.53vw 4.26vw;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 4vw;
        }
        .presistence{
            display: none;
        }
        .tileBox1Logo{
            width: 32vw;
            height: 32vw;
            background-image: url(../assets/icon1@2x.png);
            background-size:contain;
            background-position:center;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
        .tileBox2Logo{
            width: 32vw;
            height: 32vw;;
            background-image: url(../assets/icon2@2x.png);
            background-size:contain;
            background-position:center;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
        .tileBox3Logo{
            width: 32vw;
            height: 32vw;
            background-image: url(../assets/icon3@2x.png);
            background-size:contain;
            background-position:center;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
        .titleBoxTitle{
            height: 7.73vw;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 5.6vw;
            color: #FFFFFF;
            line-height: 7.73vw;
            text-align: left;
            font-style: normal;
            margin-bottom: 3.2vw;
        }
        .titleBoxWord{
            height: 38.1vw;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 3.73vw;
            color: #FFFFFF;
            line-height: 5.3vw;
            text-align: left;
            font-style: normal;
        }
        .bottom{
            width: 100%;
            height: 48vw;
            background: #FFC655;
            position: relative;
        }
        .contactInformation{
            position: absolute;
            top: 4vw;
            left: 4.27vw;
            text-align: left;
        }
        .contactInformation .title{
            display: inline-block;
            height: 8.8vw;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 4vw;
            color: #FFFFFF;
            line-height: 8.8vw;
            font-style: normal;
            -moz-user-select: none; 
            -khtml-user-select: none; 
            user-select: none;
        }
        .contactInformation p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 3.2vw;
            color: #FFFFFF;
            line-height:3.6vw;
            text-align: left;
            font-style: normal;
        }
        .QRcode{
            width: 18.6vw;
            height: 18.6vw;
            border: 1px solid #979797;
            position: absolute;
            top: 4vw;
            right: 4.27vw;
            background-image:url(../assets/qrcodeNew.png);
                background-size:contain;
                background-position:center;
        }
        .copyright{
            width: 100%;
            height: 5.33vw;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 3vw;
            color: #FFFFFF;
            text-align:center;
            font-style: normal;
            position: absolute;
            bottom: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .policeLogo{
            display: inline-block;
            display: flex;
            margin-right: 1.33vw;
        }
        .policeLogo img{
            width: 1.3vh;
        }
        .copyright a{
            color: #fff;
            /* text-decoration: none; */
        }
      }  
</style>